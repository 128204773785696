import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useCurrentCompanyId } from "hooks/useGlobalCompanyId";
import { useAccessControl } from "providers/accessControlProvider";
import { SWRResponseWithLoading, useSwaggerSwr } from "api/clients/genericApiClient";
import usersApiV1Client, { UsersApiModels } from "api/clients/usersApiV1Client";
import accessApiV2Client, { AccessApiV2Models } from "api/clients/accessApiV2Client";
import buildODataQuery from "api/clients/buildODataQuery";
import { contains, nested, or, tolower } from "api/odata/queryResolvers";
import { createFilterOptions } from "@mui/material/Autocomplete";

export const userSearchQueryResolver = (text: string): string => {
  if (!text?.length) return "";
  const sanitizedText = encodeURIComponent(text.toLowerCase()).replace(/'/g, "''");
  return nested(
    or(
      contains(tolower("displayName"), sanitizedText),
      contains(tolower("mail"), sanitizedText),
      contains(tolower("givenName"), sanitizedText),
      contains(tolower("surname"), sanitizedText),
      contains("mobilePhone", sanitizedText),
    ),
  );
};

export const useCompanyUsersCard = () => {
  const router = useRouter();
  const { currentCompanyId } = useCurrentCompanyId();

  const { hasScaleManagementPrivileges } = useAccessControl();
  const [searchValue, setSearchValue] = useState<string>("");

  const {
    data: usersCount,
    isLoading: usersCountIsLoading,
    mutate: mutateUsersCount,
  } = hasScaleManagementPrivileges
    ? (useSwaggerSwr(usersApiV1Client.UsersApi.getV1UsersCount) as unknown as SWRResponseWithLoading<number>)
    : (useSwaggerSwr(accessApiV2Client.UserAccessesApi.getV2UserAccessescount, [
        buildODataQuery<AccessApiV2Models.UserAccessData>({
          filter: {
            companyAccesses: {
              any: {
                companyGosId: {
                  in: [currentCompanyId],
                },
              },
            },
          },
        }),
      ]) as unknown as SWRResponseWithLoading<number>);

  let filter = userSearchQueryResolver(searchValue);
  if (!hasScaleManagementPrivileges) {
    filter += ` and extension_CompanyId eq '${currentCompanyId}'`;
  }

  const { data: users, isLoading: usersIsLoading } = useSwaggerSwr(
    usersApiV1Client.UsersApi.getV1Users,
    [
      buildODataQuery<UsersApiModels.UserEntity>({
        filter: filter,
      }),
    ],
    !searchValue,
  );

  const [activeUser, setActiveUser] = useState(null);
  useEffect(() => {
    if (activeUser?.id) {
      router.push({
        pathname: "/users/[userId]",
        query: { userId: activeUser.id },
      });
    }
  }, [activeUser]);

  const onInputChange = (_: React.SyntheticEvent, value: string) => {
    setSearchValue(value);
  };

  const filterOptions = createFilterOptions({
    stringify: (option: UsersApiModels.UserEntity) => {
      return option.displayName + option.mobilePhone + option.mail;
    },
  });

  return {
    usersCountIsLoading,
    usersCount,
    users,
    usersIsLoading,
    setActiveUser,
    mutateUsersCount,
    onInputChange,
    filterOptions,
    searchValue,
  };
};

import { Box, useTheme } from "@mui/material";
import { CardContentHeader } from "../CardContentHeader";
import { Operations } from "@scaleaq/scaleui-react/core/icons/medium";
import React from "react";
import { Skeleton } from "@mui/material";
import { useStyles } from "./styles";
import { useSitesCard } from "./useSitesCard";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "hooks/useNumberFormat";

export type Props = Record<string, never>;

export const SitesCardContent = (props: Props) => {
  const { classes } = useStyles(props);
  const theme = useTheme();
  const { t } = useTranslation();
  const { sites, entityGroups } = useSitesCard();

  return (
    <Box className={classes.container}>
      {(!sites || !entityGroups) && <Skeleton width={220} height={88} />}
      {sites && entityGroups && (
        <>
          <CardContentHeader
            icon={<Operations size={88} fill={theme.colors.neutral5} />}
            title={`${useNumberFormat(sites?.length)} ${t("common:sites").toLowerCase()}`}
            subtitle={`${useNumberFormat(entityGroups?.value.length)} ${t(
              "operations:entityGroups",
            ).toLowerCase()}`}
          />
        </>
      )}
    </Box>
  );
};

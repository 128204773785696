import buildODataQuery from "api/clients/buildODataQuery";
import { useSwaggerSwr } from "api/clients/genericApiClient";
import gosApiV1Client from "api/clients/gosApiV1Client";
import gosApiV2Client, { GosApiV2Models } from "api/clients/gosApiV2Client";
import { useCurrentCompanyId } from "hooks/useGlobalCompanyId";

export const useSitesCard = () => {
  const { currentCompanyId } = useCurrentCompanyId();

  const { data: availableSites } = useSwaggerSwr(gosApiV1Client.SitesApi.getApiCompaniesCompanyIdSites, [
    {
      companyId: currentCompanyId,
    },
  ]);

  const { data: availableGroups } = useSwaggerSwr(
    gosApiV2Client.GroupsApi.getApiV2GroupsGetGroupsByCompanyCompanyIdCompanyId,
    [
      {
        companyId: `'${currentCompanyId}'`,
        ...buildODataQuery<GosApiV2Models.Group>({
          expand: ["sites"],
        }),
      },
    ],
  );

  return {
    sites: availableSites,
    entityGroups: availableGroups,
  };
};

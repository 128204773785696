import { makeStyles } from "tss-react/mui";
import { Props } from "./index";

export const useStyles = makeStyles<Props>()((theme) => {
  return {
    root: { display: "flex", flexWrap: "wrap" },
    container: {
      width: "100%",
      padding: theme.spacing(2),
      "& * ~ h2, * ~ h3, * ~ span, button ~ div": { marginTop: theme.spacing(2) },
    },
    searchContainer: {
      width: "100%",
      padding: theme.spacing(2),
      backgroundColor: theme.colors.neutral1,
      borderRadius: theme.spacing(1 / 2),
    },
  };
});

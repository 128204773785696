import { CardContentHeader } from "../CardContentHeader";
import React from "react";
import { SearchBar } from "components/formComponents/SearchBar";
import { Skeleton } from "@mui/material";
import { useOperationsCard } from "./useOperationsCard";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "hooks/useNumberFormat";
import { Container, Icon } from "@scaleaq/scaleui";
import styled from "styled-components";

export const OperationsCardContent = () => {
  const { t } = useTranslation();
  const { companies, setActiveCompany } = useOperationsCard();

  return (
    <StyledContainer>
      {!companies && <Skeleton width={400} height={250} />}
      {companies && (
        <>
          <CardContentHeader
            icon={<StyledOperationIcon size={88} />}
            title={`${useNumberFormat(companies.length)} ${t("common:companies").toLowerCase()}`}
          />
          <StyledDiv>
            <SearchBar
              options={companies}
              labelKey={"companyName"}
              setActiveOption={setActiveCompany}
              name={"companySearch"}
              label={t("accessControl:searchCompany")}
              endAdornement={" "}
            />
          </StyledDiv>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  width: 100%;
`;

const StyledDiv = styled.div`
  width: 100%;
  padding: 4rem 2rem 4rem 2rem;
  margin-top: 2rem;
  background-color: #eff4f6;
  border-radius: 4px;
`;
const StyledOperationIcon = styled(Icon.Large.Operation)`
  fill: #80939f;
`;

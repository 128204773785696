import { URL_QUERY_DEFAULT_KEY } from "hooks/useSessionUrlQuery/constants";
import { LinkProps } from "next/link";
import { Route } from "nextjs-routes";
import { ParsedQuery, parseUrl, stringifyUrl } from "query-string";
import resolvePathname from "resolve-pathname";
import { UrlObject } from "url";
import { isClientSide } from "utils/commonUtils";

/**
 * A helper method to get a route with the state of filters that are stored in sessionStorage
 * on a route basis.
 *
 * Any query parameters that are present in the given link props will be preserved
 * and won't be overwritten with what's stored in sessionStorage.
 * @param basePath The base path that will be used to resolve the given props.
 * @param linkProps Same that are received by the NextLink component.
 */
export default function getRouteWithFilters(basePath: string, linkProps: LinkProps): typeof linkProps {
  const { href, as } = linkProps;
  const hrefObject: UrlObject =
    typeof href == "string"
      ? {
          pathname: href,
        }
      : href;

  const routeQuery =
    typeof href == "string" ? parseUrl(href)?.query : ((href as UrlObject)?.query as ParsedQuery);

  const _sessionStorage = isClientSide() ? sessionStorage : null;

  const resolvedPath = resolvePathname(hrefObject?.pathname, basePath);
  const defaultQueryStore = _sessionStorage?.getItem(URL_QUERY_DEFAULT_KEY) || "{}";
  const parsedDefaultQueryStore = JSON.parse(defaultQueryStore);
  const pathFilterJson = parsedDefaultQueryStore[resolvedPath];

  if (!isClientSide()) {
    return {
      ...linkProps,
      as,
      href: href as Route,
    };
  }

  const stringifiedHref = stringifyUrl({
    url: hrefObject.pathname,
    query: {
      ...routeQuery,
      [URL_QUERY_DEFAULT_KEY]: JSON.stringify({
        ...pathFilterJson,
        ...JSON.parse((routeQuery?.[URL_QUERY_DEFAULT_KEY] as string) ?? null),
      }),
    },
  });

  return {
    ...linkProps,
    href: stringifiedHref as unknown as Route,
  };
}

import { CardContentHeader } from "../CardContentHeader";
import React, { useState } from "react";
import { SearchBar } from "components/formComponents/SearchBar";
import { Skeleton } from "@mui/material";
import { useCompanyUsersCard } from "./useCompanyUsersCard";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "hooks/useNumberFormat";
import { AddNewUserModalForm } from "../../../AccessControl/Users/components/modals/AddNewUserModalForm";
import { useAccessControl } from "providers/accessControlProvider";
import { Button, Container, Icon } from "@scaleaq/scaleui";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

export const CompanyUsersCardContent = () => {
  const { t } = useTranslation();
  const {
    usersCountIsLoading,
    usersCount,
    users,
    usersIsLoading,
    setActiveUser,
    mutateUsersCount,
    onInputChange,
    filterOptions,
    searchValue,
  } = useCompanyUsersCard();

  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const { hasScaleManagementPrivileges } = useAccessControl();

  return (
    <StyledContainer>
      {usersCountIsLoading && <Skeleton width={400} height={250} />}
      {!usersCountIsLoading && (
        <>
          <CardContentHeader
            icon={<StyledUsersIcon size={88} />}
            title={
              usersCountIsLoading
                ? "..."
                : `${useNumberFormat(usersCount)} ${t("common:users").toLowerCase()}`
            }
          />

          <StyledDiv>
            <SearchBar
              options={users?.value}
              labelKey={"displayName"}
              freeSolo={true}
              setActiveOption={setActiveUser}
              label={t("common:searchUser")}
              endAdornement={
                usersIsLoading && searchValue ? (
                  <StyledLoading>
                    <CircularProgress color="inherit" size={20} />
                  </StyledLoading>
                ) : (
                  " "
                )
              }
              onInputChange={onInputChange}
              filterOptions={filterOptions}
            />
          </StyledDiv>
        </>
      )}
      {hasScaleManagementPrivileges && (
        <StyledButton leadIcon={<Icon.Large.Add />} onClick={() => setNewUserModalOpen(true)}>
          {t("home:addNewUser")}
        </StyledButton>
      )}

      {hasScaleManagementPrivileges && (
        <AddNewUserModalForm
          open={newUserModalOpen}
          onClose={() => setNewUserModalOpen(false)}
          onSubmit={mutateUsersCount}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  width: 100%;
`;

const StyledDiv = styled.div`
  width: 100%;
  padding: 4rem 2rem 4rem 2rem;
  margin-top: 2rem;
  background-color: #eff4f6;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
`;
const StyledUsersIcon = styled(Icon.Large.Users)`
  fill: #80939f;
`;
const StyledLoading = styled.div`
  margin-right: 1rem;
  margin-top: 0.5rem;
`;

import { Breadcrumb, Button, Row, TitleBar } from "@scaleaq/scaleui";
import { ButtonProps } from "@scaleaq/scaleui-react/core/components/Buttons/commonButtonTypes";
import getRouteWithFilters from "components/common/Link/logic/getRouteWithFilters";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { Route } from "nextjs-routes";
import React, { useMemo } from "react";

export type BreadCrumb = Omit<LinkProps, "href"> & {
  icon?: JSX.Element;
  key: string;
  text: string;
  href: Route;
};

export type Props = {
  title: string;
  breadcrumbs?: BreadCrumb[];
  showButton?: boolean;
  customButton?: React.ReactElement;
  buttonProps?: ButtonProps;
  menu?: React.ReactElement;
  ref?: React.Ref<HTMLButtonElement>;
  customTitleElement?: React.ReactElement;
  location?: string;
  enableBack?: boolean;
};

export default function ManagementPortalTitleBar(props: Props) {
  const router = useRouter();

  const {
    title,
    breadcrumbs,
    customTitleElement,
    customButton,
    menu,
    showButton: button,
    buttonProps,
    enableBack,
  } = props;

  const mappedBreadCrumbs: Breadcrumb[] = breadcrumbs?.map(
    (breadCrumb) => getRouteWithFilters(router.asPath, breadCrumb) as Breadcrumb,
  );

  const goBack = () => {
    window.history.back();
  };

  const buttonPropsMapped = useMemo(() => {
    const { ...propsToKeep } = buttonProps || {};
    return propsToKeep;
  }, [buttonProps]);

  return (
    <TitleBar
      title={title}
      breadcrumbs={mappedBreadCrumbs}
      customTitleElement={customTitleElement}
      LinkComponent={Link}
      data-testid="managementPortalTitleBar"
      goBack={enableBack ? goBack : null}
      customElement={
        <Row>
          {customButton}
          {menu}
          {button && (
            <Button
              data-testid="newButton"
              {...buttonPropsMapped}
              color="primary"
              leadIcon={buttonProps?.startIcon as React.ReactElement}
              variant="solid"
            >
              {buttonProps?.children}
            </Button>
          )}
        </Row>
      }
    />
  );
}

import { useSwaggerSwr } from "api/clients/genericApiClient";
import gosApiV1Client from "api/clients/gosApiV1Client";
import { GosApiV2Models } from "api/clients/gosApiV2Client";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { sortByProperty } from "utils/arrayUtils";

export const useOperationsCard = () => {
  const { data: companies } = useSwaggerSwr(gosApiV1Client.CompaniesApi.getApiCompanies);
  const sortedCompanies = sortByProperty(companies, "companyName", { ascending: true });
  const router = useRouter();
  const lastAddedCompany = "Some Company";

  const [activeCompany, setActiveCompany] = useState<GosApiV2Models.Company>(null);
  useEffect(() => {
    if (activeCompany) {
      router.push({
        pathname: "/companies/[companyId]",
        query: { companyId: activeCompany.companyId },
      });
    }
  }, [activeCompany]);

  return {
    companies: sortedCompanies,
    lastAddedCompany,
    setActiveCompany,
  };
};

import { TFunction } from "i18n";
const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
const PHONE_REGEX = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const NUMBER_REGEX = new RegExp(/^\d+$/);
const IP_REGEX =
  /^(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])$/;
const PREFIX_IP_REGEX =
  /^(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\.(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])$/;

export const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;
export const isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180;

export const validatePhone = (value: string, t: TFunction) => {
  if (!value) {
    return null;
  }
  return (!PHONE_REGEX.test(value) && t("common:invalidFormat")) || null;
};

export const validateEmail = (email: string, t: TFunction) => {
  return (!EMAIL_REGEX.test(email) && t("common:invalidFormat")) || null;
};

export const isValidEmail = (v: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(v);
};

export const validateUniqueValue = (value: string, values: string[], errorString: string): string => {
  if (!values || values.length == 0) {
    return null;
  }

  if (values.find((v) => v == value)) {
    return errorString;
  }

  return null;
};

export const validateNumber = (value: string, t: TFunction): string => {
  if (!value) {
    return null;
  }

  return NUMBER_REGEX.test(value) ? null : t("common:invalidFormat");
};

export const validateLatitude = (value: number, t: TFunction): string => {
  if (!value) {
    return null;
  }

  return isLatitude(+value) ? null : t("common:invalidFormat");
};

export const validateLongitude = (value: number, t: TFunction): string => {
  if (!value) {
    return null;
  }

  return isLongitude(+value) ? null : t("common:invalidFormat");
};

export const validateIpAddress = (value: string, t: TFunction, prefix = false): string => {
  if (!value) {
    return null;
  }
  if (prefix) {
    return PREFIX_IP_REGEX.test(value) ? null : t("common:invalidFormat");
  } else {
    return IP_REGEX.test(value) ? null : t("common:invalidFormat");
  }
};

import { NO_DATE_FORMAT, ISO_DATE_FORMAT_WITH_TIME_SECOND } from "config/formConfig";
import { DateTime } from "luxon";

type SortOptions = {
  locale?: string | string[];
  ascending?: boolean;
  sortAsDate?: boolean;
  dateFormat?: string;
};

export const sortByProperty = <T>(list: T[], property?: keyof T, sortOptions: SortOptions = {}): T[] => {
  const {
    locale = undefined,
    ascending = true,
    sortAsDate = false,
    dateFormat = NO_DATE_FORMAT,
  } = sortOptions;
  // TODO: make sure to inspect all references to this method,
  // as it was previously mutating the original collection,
  // which for some reason broke SWR's state.
  // Added a spread for safety and to prevent this from happening.
  // For future readers, never mutate server data directly. Always mutate it through SWR hooks, or clone/spread as needed.
  return [...(list ?? [])].sort((a, b) => {
    // TODO: better generics
    let aValue;
    let bValue;
    try {
      aValue = property
        ? (a[property] as unknown as string)?.toLowerCase()
        : (a as unknown as string)?.toLowerCase();
      bValue = property
        ? (b[property] as unknown as string)?.toLowerCase()
        : (b as unknown as string)?.toLowerCase();
    } catch (e) {
      aValue = property ? (a[property] as unknown as string) : (a as unknown as string);
      bValue = property ? (b[property] as unknown as string) : (b as unknown as string);
    }

    if (sortAsDate) {
      const aDate =
        dateFormat === ISO_DATE_FORMAT_WITH_TIME_SECOND
          ? DateTime.fromISO(aValue)
          : DateTime.fromFormat(aValue, dateFormat);
      const bDate =
        dateFormat === ISO_DATE_FORMAT_WITH_TIME_SECOND
          ? DateTime.fromISO(bValue)
          : DateTime.fromFormat(bValue, dateFormat);
      return numericOrDateSort(aDate, bDate, ascending);
    }

    if (typeof aValue == "number" && typeof bValue == "number") {
      return numericOrDateSort(aValue, bValue, ascending);
    }

    if (ascending) {
      return aValue?.toString().localeCompare(bValue, locale, { sensitivity: "base", numeric: true });
    }
    return bValue?.toString().localeCompare(aValue, locale, { sensitivity: "base", numeric: true });
  });
};

export const numericOrDateSort = (a: DateTime | number, b: DateTime | number, ascending: boolean) => {
  if (a < b) return ascending ? -1 : 1;
  if (a == b) return 0;
  if (a > b) return ascending ? 1 : -1;
};

import { Container, Row, Surface } from "@scaleaq/scaleui";
import { PageSurface } from "components/common/Global";
import { Card } from "components/skeleton/Card";
import ManagementPortalTitleBar from "components/skeleton/ManagementPortalTitleBar";
import { useCurrentCompanyId } from "hooks/useGlobalCompanyId";
import { useTranslation } from "react-i18next";
import { AccessTypesCardContent } from "./components/AccessTypesCardContent";
import { CompanyUsersCardContent } from "./components/CompanyUsersCardContent";
import { OperationsCardContent } from "./components/OperationsCardContent";
import { SitesCardContent } from "./components/SitesCardContent";
import { useHomePage } from "./useHomePage";
import styled from "styled-components";
import { useRouter } from "next/router";

const HomePage = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const { hasScaleManagementPrivileges, isCustomerAdminForCurrentCompany, accessIsLoading, isSiteAdmin } =
    useHomePage();

  const { currentCompanyId } = useCurrentCompanyId();

  // the checks below would be better placed in some central auth-per-route config file
  if (accessIsLoading) {
    return null;
  }

  if (hasScaleManagementPrivileges == false && isCustomerAdminForCurrentCompany == false) {
    if (isSiteAdmin) {
      router.push("/sites");
    }

    return null;
  }

  return (
    <StyledPageSurface>
      <ManagementPortalTitleBar title={t("home:title")} breadcrumbs={[]} />
      <Surface color="surface4">
        <Container pb="4.8rem" px="3.2rem" py="1.6rem">
          <StyledRow gap="3.2rem">
            {(hasScaleManagementPrivileges || isCustomerAdminForCurrentCompany) && (
              <Card
                title={t("common:users")}
                link={{ displayText: t("home:viewAllUsers"), href: { pathname: "/users" } }}
              >
                {!accessIsLoading && <CompanyUsersCardContent />}
              </Card>
            )}
            {hasScaleManagementPrivileges && (
              <Card
                title={t("common:operations")}
                link={{ displayText: t("home:viewOperations"), href: { pathname: `/companies` } }}
              >
                {!accessIsLoading && <OperationsCardContent />}
              </Card>
            )}
          </StyledRow>
          {isCustomerAdminForCurrentCompany && (
            <StyledRow>
              <Card
                title={t("common:sites")}
                link={{
                  displayText: t("home:viewAllSites"),
                  href: { pathname: `/sites` },
                }}
              >
                <SitesCardContent />
              </Card>

              <Card
                title={t("accessControl:accessTypes")}
                link={{
                  displayText: t("home:viewAccessTypes"),
                  href: {
                    pathname: "/companies/[companyId]/access-types",
                    query: { companyId: currentCompanyId },
                  },
                }}
              >
                <AccessTypesCardContent />
              </Card>
            </StyledRow>
          )}
        </Container>
      </Surface>
    </StyledPageSurface>
  );
};

export default HomePage;

const StyledPageSurface = styled(PageSurface)`
  background: #e6ecf0;
`;
const StyledRow = styled(Row)`
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  gap: 3.2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > div {
    flex-basis: 100%;
  }
`;

import { CardContentHeader } from "../CardContentHeader";
import React from "react";
import { Skeleton } from "@mui/material";
import { useAccessTypesCard } from "./useAccessTypesContent";
import { useTranslation } from "react-i18next";
import { useNumberFormat } from "hooks/useNumberFormat";
import { Container, Icon } from "@scaleaq/scaleui";
import styled from "styled-components";

export const AccessTypesCardContent = () => {
  const { t } = useTranslation();
  const { companyAccessGroups } = useAccessTypesCard();

  return (
    <Container>
      {!companyAccessGroups && <Skeleton width={220} height={190} />}
      {companyAccessGroups && (
        <>
          <CardContentHeader
            icon={<StyledAccessIcon size={88} />}
            title={`${useNumberFormat(companyAccessGroups.length)} ${t(
              "accessControl:accessTypes",
            ).toLowerCase()}`}
          />
        </>
      )}
    </Container>
  );
};

const StyledAccessIcon = styled(Icon.Large.Access)`
  fill: #80939f;
`;

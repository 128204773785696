import accessApiV1Client from "api/clients/accessApiV1Client";
import { useSwaggerSwr } from "api/clients/genericApiClient";
import { useCurrentCompanyId } from "hooks/useGlobalCompanyId";

export const useAccessTypesCard = () => {
  const { currentCompanyId } = useCurrentCompanyId();

  const { data: companyAccessGroups } = useSwaggerSwr(
    accessApiV1Client.AccessGroupsApi.getAccessGroupsCompanyCompanyId,
    [
      {
        companyId: currentCompanyId,
      },
    ],
  );

  return {
    companyAccessGroups,
  };
};

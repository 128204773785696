import React from "react";
import { Column, Container, Row, Typography } from "@scaleaq/scaleui";
import styled from "styled-components";

export type Props = {
  icon?: React.ReactNode;
  title: string;
  subtitle?: string;
};

export const CardContentHeader = (props: Props) => {
  const { icon, title, subtitle } = props;
  return (
    <Container>
      <Column>
        <Row alignItems="center" gap="3.2rem">
          {icon && <Row>{icon}</Row>}
          <Column>
            <Typography type="h1">{title}</Typography>
            {subtitle && <StyledTypography type="caption_medium">{subtitle}</StyledTypography>}
          </Column>
        </Row>
      </Column>
    </Container>
  );
};

const StyledTypography = styled(Typography)`
  margin-top: 0;
  font-size: 14px;
  font-weight: unset;
`;

import { useAccessControl } from "providers/accessControlProvider";

export const useHomePage = () => {
  const {
    isCustomerAdminForCurrentCompany,
    isSiteAdminInCurrentCompany,
    accessIsLoading,
    userAccess,
    hasScaleManagementPrivileges,
    isSiteAdmin,
  } = useAccessControl();

  return {
    userAccess,
    hasScaleManagementPrivileges,
    isCustomerAdminForCurrentCompany,
    isSiteAdminInCurrentCompany,
    accessIsLoading,
    isSiteAdmin,
  };
};

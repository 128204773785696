import { Column, Container, Row, Surface, Typography } from "@scaleaq/scaleui";
import React from "react";
import styled from "styled-components";
import { Route } from "nextjs-routes";
import Link from "next/link";

export type Props = {
  children: React.ReactNode;
  title: string;
  link?: LinkProps;
};

type LinkProps = {
  displayText: string;
  href: Route;
};

export const Card = (props: Props) => {
  const { children, title, link } = props;
  return (
    <StyledSurface rounded>
      <Column>
        <StyledSurface color="surface3">
          <StyledContainer px="1.6rem" py="2rem">
            <Row justifyContent="space-between">
              <Typography type="sub_heading">{title}</Typography>

              {link && <Link href={link.href}>{link.displayText} </Link>}
            </Row>
          </StyledContainer>
        </StyledSurface>
        <Row>{children}</Row>
      </Column>
    </StyledSurface>
  );
};

const StyledSurface = styled(Surface)`
  display: flex;
  width: 100%;
  & > * {
    width: 100%;
  }
`;

const StyledContainer = styled(Container)`
  background: #f1f4f6;
`;
